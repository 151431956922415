import { updateDomAsync, useConsent } from '@lib/gdpr';

import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useEffect } from 'react';
import ConsentRequiredFallback from '@components/molecules/ConsentRequiredFallback';

export default function PartsStore(props) {
  const {
    display,
    dealerId,
    series,
    model,
    hsn,
    tsn,
    fin,
    currency,
    storageId,
  } = props;
  const hasConsent = useConsent('partsStore');

  const data = {
    'data-language': 'de',
    'data-dealerid': dealerId,
    'data-series': series,
    'data-model': model,
    'data-hsn': hsn,
    'data-tsn': tsn,
    'data-fin': fin,
    'data-currencycode': currency,
    'data-storageid': storageId,
  };

  useEffect(() => {
    updateDomAsync();
  }, []);
  useEffect(() => {
    if (!hasConsent) {
      return;
    }

    const scripts = [];

    if (display === 'fullShop') {
      const script1 = document.createElement('script');
      script1.src = 'https://hpm.itt-dev.de/static/js/main.js';
      document.body.appendChild(script1);
      scripts.push(script1);
    } else {
      const script1 = document.createElement('script');
      script1.src = 'https://selekt.sl-gcp.itt-dev.de/static/js/runtime-main.js';
      document.body.appendChild(script1);
      scripts.push(script1);

      const script2 = document.createElement('script');
      script2.src = 'https://selekt.sl-gcp.itt-dev.de/static/js/main.js';
      document.body.appendChild(script2);
      scripts.push(script2);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const script of scripts) {
        document.body.removeChild(script);
      }
    };
  }, [hasConsent]);

  if (!hasConsent) {
    return <ConsentRequiredFallback />;
  }

  return display === 'fullShop'
    ? (
      <>
        <div
          data-dealer-id={dealerId}
          data-environment="own"
          id="app-root"
        />
        <link href="https://hpm.itt-dev.de/static/css/main.css" rel="stylesheet" />
      </>
    )
    : (
      <>
        <div
          {...omitBy(data, isNil)}
          className="vehicle-cart"
          id="root"
        />
        <link href="https://selekt.sl-gcp.itt-dev.de/static/css/main.css" rel="stylesheet" />
      </>

    );
}

PartsStore.propTypes = {
  dealerId: PropTypes.string,
  series: PropTypes.string,
  model: PropTypes.string,
  hsn: PropTypes.string,
  tsn: PropTypes.string,
  fin: PropTypes.string,
  currency: PropTypes.number,
  storageId: PropTypes.string,
  display: PropTypes.oneOf([null, 'fullShop', 'modelShop']),
};
