import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@mui/material/styles';
import { resolveCdnUrl } from '@lib/utils/cdn';

const StyledImg = styled('img')(({
  theme, isSquare, isFluid, styles,
}) => ({
  ...(isSquare ? {} : {
    borderRadius: theme.shape.borderRadius,
  }),
  ...(isFluid ? {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  } : {}),
  ...styles,
}));

export default function Image(props) {
  const {
    src,
    alt,
    isFluid,
    isSquare,
    styles,
    className,
    ...otherProps
  } = props;

  let prefixedSrc = src;

  if (src && !src.startsWith('http') && !src.startsWith('//')) {
    prefixedSrc = resolveCdnUrl(src);
  }

  const sources = [{ srcSet: prefixedSrc, type: 'image/jpeg' }];

  return (
    <picture>
      {sources.map(({ srcSet, type }) => (
        <source key={type} srcSet={srcSet} type={type} />
      ))}
      <StyledImg
        {...otherProps}
        alt={alt}
        className={className}
        isFluid={isFluid}
        isSquare={isSquare}
        loading="lazy"
        src={prefixedSrc}
        styles={styles}
      />
    </picture>
  );
}

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  isFluid: PropTypes.bool,
  isSquare: PropTypes.bool,
};
