import * as yup from 'yup';

export default yup.object().shape({
  // saluation: yup.string().required(),
  branchEmail: yup.string().required(),
  name: yup.string().min(1).max(100).required(),
  email: yup.string().min(1).max(255).email()
    .required(),
  phone: yup.string().min(1).max(100).required(),
  message: yup.string().min(2).max(5000).required(),
  // isTermsAndPrivacyAccepted: yup.bool().isTrue()
});
