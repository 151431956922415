import { gql, useMutation } from '@apollo/client';

export default function useSendEmail() {
  const [queueEmail] = useMutation(QUEUE_EMAIL_MUTATION);

  return async (data) => {
    const result = await queueEmail({
      variables: { data },
    });

    return result.data.queueEmail;
  };
}

const QUEUE_EMAIL_MUTATION = gql`
mutation QueueEmail($data: JSON!) {
  queueEmail(input: $data) {
    status
    message
  }
}
`;
