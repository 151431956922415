import { useState } from 'react';

export const FormState = {
  INITIAL: 'initial',
  SUCCESS: 'success',
  ERROR: 'error',
};

export default function useFormState() {
  const [formState, setFormState] = useState(FormState.INITIAL);

  return {
    formState,
    handleGraphQlResult: (result) => {
      if (result.status === 'success') {
        setFormState(FormState.SUCCESS);
      } else {
        setFormState(FormState.ERROR);
      }
    },
    resetFormState: () => {
      setFormState(FormState.INITIAL);
    },
  };
}
