import React from 'react';
import { TextField } from '@mui/material';

export default function TextInput(props) {
  const { InputProps, multiline } = props;

  return (
    <TextField
      {...props}
      slotProps={{
        input: {
          ...InputProps,
          disableUnderline: true,
          className: InputProps?.className,
          sx: {
            paddingTop: multiline ? 1 : 0,
          },
        },
      }}
    />
  );
}

TextInput.propTypes = {
  ...TextField.propTypes,
};
