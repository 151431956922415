import { Button, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useConsentManager } from '@lib/gdpr';
import Container from '../atoms/Container';

const RootPaper = styled(Paper)(({ theme }) => ({
  minHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function ConsentRequiredFallback() {
  const { changeConsent } = useConsentManager();

  const handleChangeConsent = () => {
    changeConsent();
  };

  return (
    <Container>
      <RootPaper variant="outlined">
        <Typography component="div" gutterBottom textAlign="center" variant="h6">
          Zustimmung für externe Inhalte erforderlich
        </Typography>
        <Typography textAlign="center" variant="body2">
          Um diesen Inhalt anzeigen zu können, ist Ihre Zustimmung erforderlich.
        </Typography>
        <Button
          color="primary"
          onClick={handleChangeConsent}
          sx={{ mt: 3 }}
          variant="contained"
        >
          Datenschutz-Zustimmung anpassen
        </Button>
      </RootPaper>
    </Container>
  );
}
